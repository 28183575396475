function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import { HP_SOURCE, PAGE_VIEW, TRACK_EVENT, STORIES_SOURCE, HP_GROUP } from "/constants/bigbrain-event-types";
import { truncateQueryParams } from "/utils/url";
import { isServerSide } from "utils/general";
import { getCookie } from "/services/cookies-service";
import { BIG_BRAIN_VISITOR_ID } from "/constants/cookies";
import { BIGBRAIN_TRACKING_URL } from "/constants/analytics";
import { CONSTRUCTION, HR, IT, OPERATIONS, PMO, PRODUCT_MANAGEMENT, TASKS_AND_TODOS, DESIGN_AND_CREATIVE, FINANCE, CRM, SOFTWARE, WORK_MANAGEMENT, MARKETING } from "../constants/clusters";
import { WORK_MANAGEMENT_PRODUCT_ID, DEV_PRODUCT_ID, CRM_PRODUCT_ID } from "../constants/products";
const SET_UTM_CLUSTER_ID = "set_utm_cluster_id";
const SET_UTM_SUB_CLUSTER_ID = "set_utm_sub_cluster_id";
const SET_UTM_LOCALE_ID = "set_utm_locale_id";
const MILLISECONDS_IN_SECONDS = 1000;
const createEventData = (data = {})=>{
    const timestamp = parseInt(new Date().getTime() / MILLISECONDS_IN_SECONDS);
    const source = data.source || HP_SOURCE;
    const group = data.group || HP_GROUP;
    return _object_spread({
        timestamp,
        source,
        group
    }, data);
};
const createLocalBigbrain = ()=>(originEvent, eventName, data = {})=>{
        if (originEvent === TRACK_EVENT) {
            const eventData = createEventData(data);
            const stringifyEventData = JSON.stringify(eventData);
            console.info([
                originEvent,
                eventName,
                stringifyEventData
            ]);
        }
    };
export const initBigBrainTracker = (isProduction)=>{
    window.BigBrainQ = [];
    if (isProduction) {
        window.BigBrain = new BigBrainTracker({
            bigbrain_url: process.env.BIGBRAIN_ENDPOINT,
            send_immediately: true
        });
    } else {
        window.BigBrain = createLocalBigbrain();
    }
};
export const trackEvent = (eventName, data = {})=>{
    if (isServerSide()) {
        console.log("bigbrain-service: trackEvent does not emit events from the server-side to BigBrain. If you must emit during a server call or render, use trackBigBrainEventOnServer().");
        return;
    }
    const eventData = createEventData(data);
    if (window.BigBrain) {
        window.BigBrain(TRACK_EVENT, eventName, eventData);
    } else {
        console.log("Missing BigBrain in window");
    }
};
export const trackEventImmediately = function() {
    var _ref = _async_to_generator(function*(name, data = {}) {
        const eventData = createEventData(data);
        const metadata = {
            bbVisitorId: getCookie(BIG_BRAIN_VISITOR_ID),
            screen_height: screen.height,
            screen_width: screen.width,
            os_language: navigator.language,
            user_agent: navigator.userAgent
        };
        const mergedData = [
            _object_spread_props(_object_spread({}, eventData, metadata), {
                name
            })
        ];
        const body = JSON.stringify(mergedData);
        return fetch(BIGBRAIN_TRACKING_URL, {
            body,
            method: "POST",
            keepalive: true,
            headers: {
                "Content-Type": "application/json"
            }
        });
    });
    return function trackEventImmediately(name) {
        return _ref.apply(this, arguments);
    };
}();
export const setUtmClusterId = (clusterId)=>{
    if (window.BigBrain) {
        window.BigBrain(SET_UTM_CLUSTER_ID, clusterId);
    }
};
export const setUtmSubClusterId = (subClusterId)=>{
    return; // commented out until supported on bigbrain side
    if (window.BigBrain) {
        window.BigBrain(SET_UTM_SUB_CLUSTER_ID, subClusterId);
    }
};
export const setUtmLocaleId = (localeId)=>{
    if (window.BigBrain) {
        window.BigBrain(SET_UTM_LOCALE_ID, localeId);
    }
};
export const trackCommunityEvent = (eventName, data = {})=>{
    data.source = STORIES_SOURCE;
    if (window.currentUser) {
        data.pulse_user_id = data.pulse_user_id || window.currentUser.mondayUserId;
        data.pulse_account_id = data.pulse_account_id || window.currentUser.mondayAccountId;
    } else if (window.mondayUserId) {
        data.pulse_user_id = data.pulse_user_id || window.mondayUserId;
    }
    const eventData = createEventData(data);
    if (window.BigBrain) {
        try {
            window.BigBrain(TRACK_EVENT, eventName, eventData);
        } catch (error) {
            console.log("error trackCommunityEvent ", error);
        }
    } else {
        console.log("Missing BigBrain in window");
    }
};
export const trackBigBrainPageView = (pathname = window.location.pathname, clusterId, subClusterId)=>{
    pathname = truncateQueryParams(pathname);
    pathname = pathname[pathname.length - 1] === "/" ? pathname : pathname + "/";
    const data = {
        kind: pathname,
        info3: location.href,
        direct_object: window.visitGuid,
        info1: clusterId ? `${clusterId}${subClusterId ? "," + subClusterId : ""}` : ""
    };
    trackEvent(PAGE_VIEW, data);
};
