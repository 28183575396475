const COOKIE_CATEGORIES = {
    NECESSARY: "necessary",
    FUNCTIONAL: "functional",
    ANALYTICS: "analytics",
    MARKETING: "marketing" //shouldn't be used in the code only via the GTM this is the only opt-in cookie
};
const COOKIE_CATEGORIES_GTM_MAPPINGS = {
    [COOKIE_CATEGORIES.NECESSARY]: {
        gtmAllowedDataLayerVariable: "cookiehub_necessary"
    },
    [COOKIE_CATEGORIES.FUNCTIONAL]: {
        gtmAllowedDataLayerVariable: "cookiehub_preferences"
    },
    [COOKIE_CATEGORIES.ANALYTICS]: {
        gtmAllowedDataLayerVariable: "cookiehub_analytics"
    },
    [COOKIE_CATEGORIES.MARKETING]: {
        gtmAllowedDataLayerVariable: "cookiehub_marketing"
    }
};
module.exports = {
    COOKIE_CATEGORIES,
    COOKIE_CATEGORIES_GTM_MAPPINGS
};
