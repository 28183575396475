function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function _object_without_properties(source, excluded) {
    if (source == null) return {};
    var target = _object_without_properties_loose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _object_without_properties_loose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
const { getLogger } = require("@mondaydotcomorg/monday-logger");
const { AB_TEST_EXACT_EVENT_NAME, REFERER_HEADER, CLOUDFRONT_MOBILE_HEADER, CLOUDFRONT_TABLET_HEADER, CLOUDFRONT_DESKTOP_HEADER, CLOUDFRONT_VIEWER_COUNTRY_HEADER } = require("../../constants/headers");
const { InternalServerError } = require("../extensions/error-extensions");
const { ERROR_LOG_LEVEL, INFO_LOG_LEVEL, WARNING_LOG_LEVEL, DEBUG_LOG_LEVEL } = require("./consts");
const { REQ_PROP_PATH, REQ_PROP_URL, REQ_PROP_REFERER, REQ_PROP_CLOUDFRONT_MOBILE_HEADER, REQ_PROP_CLOUDFRONT_TABLET_HEADER, REQ_PROP_CLOUDFRONT_DESKTOP_HEADER, REQ_PROP_CLOUDFRONT_VIEWER_COUNTRY } = require("./consts");
const { RES_PROP_AB_TEST_EXACT_EVENT_NAME } = require("./consts");
const getReqParams = (req)=>{
    if (!req) return {};
    return {
        [REQ_PROP_PATH]: req.path,
        [REQ_PROP_URL]: req.url,
        [REQ_PROP_REFERER]: req.headers[REFERER_HEADER],
        [REQ_PROP_CLOUDFRONT_MOBILE_HEADER]: req.headers[CLOUDFRONT_MOBILE_HEADER],
        [REQ_PROP_CLOUDFRONT_TABLET_HEADER]: req.headers[CLOUDFRONT_TABLET_HEADER],
        [REQ_PROP_CLOUDFRONT_DESKTOP_HEADER]: req.headers[CLOUDFRONT_DESKTOP_HEADER],
        [REQ_PROP_CLOUDFRONT_VIEWER_COUNTRY]: req.headers[CLOUDFRONT_VIEWER_COUNTRY_HEADER]
    };
};
const getResParams = (res)=>{
    if (!res) return {};
    return {
        [RES_PROP_AB_TEST_EXACT_EVENT_NAME]: res.getHeader(AB_TEST_EXACT_EVENT_NAME)
    };
};
const log = (logLevel, { message, req, res, params })=>{
    try {
        const logger = getLogger();
        const loggerParams = _object_spread({}, getReqParams(req), getResParams(res), params);
        switch(logLevel){
            case INFO_LOG_LEVEL:
                logger.info(_object_spread({}, loggerParams), message);
                break;
            case WARNING_LOG_LEVEL:
                logger.warn(_object_spread({}, loggerParams), message);
                break;
            case ERROR_LOG_LEVEL:
                logger.error(_object_spread({}, loggerParams), message);
                break;
            case DEBUG_LOG_LEVEL:
            default:
                logger.debug(_object_spread({}, loggerParams), message);
                break;
        }
    } catch (ex) {
        console.log("Logging failed.");
    }
};
const logInfo = (message, paramaters = {})=>{
    const { req, res } = paramaters, params = _object_without_properties(paramaters, [
        "req",
        "res"
    ]);
    log(INFO_LOG_LEVEL, {
        message,
        req,
        res,
        params
    });
};
const logError = (message, paramaters = {})=>{
    const { req, res } = paramaters, params = _object_without_properties(paramaters, [
        "req",
        "res"
    ]);
    log(ERROR_LOG_LEVEL, {
        message,
        req,
        res,
        params
    });
};
const logWarning = (message, paramaters = {})=>{
    const { req, res } = paramaters, params = _object_without_properties(paramaters, [
        "req",
        "res"
    ]);
    log(WARNING_LOG_LEVEL, {
        message,
        req,
        res,
        params
    });
};
const logDebug = (message, paramaters = {})=>{
    const { req, res } = paramaters, params = _object_without_properties(paramaters, [
        "req",
        "res"
    ]);
    log(DEBUG_LOG_LEVEL, {
        message,
        req,
        res,
        params
    });
};
const logException = ({ externalMessage, e, req, res, extraLogParams })=>{
    try {
        let logMessage = "Unhandled exception";
        let logParams;
        if (e instanceof InternalServerError) {
            const { message, params } = e;
            logMessage = "Internal server error";
            logParams = _object_spread_props(_object_spread({}, params), {
                exceptionMessage: message
            });
        } else {
            logParams = {
                exceptionMessage: e && e.message != null ? e.message : e
            };
        }
        logParams = _object_spread_props(_object_spread({}, logParams, extraLogParams), {
            externalMessage
        });
        log(ERROR_LOG_LEVEL, {
            message: logMessage,
            params: logParams,
            req,
            res
        });
    } catch (ex) {
        console.log("Logging exception failed.");
    }
};
module.exports = {
    log,
    logException,
    logInfo,
    logWarning,
    logError,
    logDebug
};
