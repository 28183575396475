//  names of clusters
const MARKETING = "marketing";
const CRM = "crm";
const NON_PROFIT = "ngo";
const OPERATIONS = "operations";
const DESIGN_AND_CREATIVE = "design_and_creative";
const SOFTWARE = "tech";
const HR = "hr";
const IT = "information_technology";
const EDUCATION = "education";
const CONSTRUCTION = "construction";
const FINANCE = "finance";
const PMO = "pmo";
const PRODUCT_MANAGEMENT = "product_management";
const TASKS_AND_TODOS = "tasks_and_todos";
const WORK_MANAGEMENT = "work_management";
const CREATIVE = "creative";
const FUNDRAISING = "fundraising";
const PROJECTS_FLOW = "projects_flow";
// SubCluster
const WORK_DOCS_SUB_CLUSTER = "workdocs";
/****  names of use cases - Start ****/ const PROJECT_MANAGEMENT_USE_CASE = "project_management";
// work management
const PROJECT_AND_TASK_MANAGEMENT = "project_and_task_management";
const TASK_MANAGEMENT = "tasks";
const WORKLOAD_MANAGEMENT = "workload_management";
const CLIENT_PROJECTS = "client_projects";
const PORTFOLIO_MANAGEMENT = "managing_projects_portfolio";
const BUSINESS_OPERATIONS = "business_operations";
const GOALS_AND_OKRS = "goals_and_okrs";
const REQUEST_MANAGEMENT = "request_management";
const STRATEGIC_PLANNING = "strategic_planning";
const RESOURCE_MANAGEMENT = "resource_management";
// crm
const SALES_PIPELINE = "sales_pipeline";
const CONTACT_MANAGEMENT = "contact_management";
const LEADS_CAPTURING = "leads_capturing";
const LEAD_MANAGEMENT = "lead_management";
const PERFORMANCE_TRACKING = "performance_tracking";
const EMAIL_SYNC = "email_sync";
const CUSTOMER_PROJECTS = "customer_projects";
const CUSTOMER_ONBOARDING = "customer_onboarding";
// dev
const SPRINT_MANAGEMENT = "sprint_management";
const BUGS_TRACKING = "bugs_tracking";
const SCRUM_SOFTWARE = "scrum_software";
const FEATURES_BACKLOG = "features_backlog";
const FEATURE_REQUESTS = "features_requests";
const FEEDBACK_MANAGEMENT = "feedback_management";
const RELEASE_PLAN = "release_plan";
// dev - cluster not supported yet
const SPRINT_RETROSPECTIVE = "sprint_retrospective";
const ROADMAP_PLANNING = "roadmap_planning";
const AGILE_PROJECTS = "agile_projects";
// service
const TICKET_MANAGEMENT = "ticket_management";
const EMPLOYEE_LIFECYCLE = "employee_lifecycle";
const INVENTORY_AND_ORDERS = "inventory_and_orders";
const CUSTOMER_PORTAL = "customer_portal";
const ASSET_MANAGEMENT = "asset_management";
const SERVICE_CATALOG = "service_catalog";
/****  names of use cases - End ****/ const DEFAULT = "default"; // no cluster
const GENERIC_CLUSTER = "generic";
//  events where you get clusters
const HP_TAGS_SELECTION = "hp_tags_selection";
module.exports = {
    MARKETING,
    CRM,
    DEFAULT,
    GENERIC_CLUSTER,
    HP_TAGS_SELECTION,
    NON_PROFIT,
    OPERATIONS,
    DESIGN_AND_CREATIVE,
    SOFTWARE,
    HR,
    IT,
    PROJECT_MANAGEMENT_USE_CASE,
    EDUCATION,
    CONSTRUCTION,
    WORK_DOCS_SUB_CLUSTER,
    FINANCE,
    PROJECT_AND_TASK_MANAGEMENT,
    TASK_MANAGEMENT,
    WORKLOAD_MANAGEMENT,
    CLIENT_PROJECTS,
    PORTFOLIO_MANAGEMENT,
    BUSINESS_OPERATIONS,
    GOALS_AND_OKRS,
    REQUEST_MANAGEMENT,
    STRATEGIC_PLANNING,
    RESOURCE_MANAGEMENT,
    SALES_PIPELINE,
    CONTACT_MANAGEMENT,
    LEADS_CAPTURING,
    LEAD_MANAGEMENT,
    SPRINT_MANAGEMENT,
    BUGS_TRACKING,
    SCRUM_SOFTWARE,
    FEATURES_BACKLOG,
    FEATURE_REQUESTS,
    FEEDBACK_MANAGEMENT,
    RELEASE_PLAN,
    SPRINT_RETROSPECTIVE,
    TASKS_AND_TODOS,
    ROADMAP_PLANNING,
    AGILE_PROJECTS,
    PERFORMANCE_TRACKING,
    EMAIL_SYNC,
    CUSTOMER_PROJECTS,
    CUSTOMER_ONBOARDING,
    PMO,
    PRODUCT_MANAGEMENT,
    WORK_MANAGEMENT,
    CREATIVE,
    FUNDRAISING,
    PROJECTS_FLOW,
    TICKET_MANAGEMENT,
    EMPLOYEE_LIFECYCLE,
    INVENTORY_AND_ORDERS,
    CUSTOMER_PORTAL,
    ASSET_MANAGEMENT,
    SERVICE_CATALOG
};
