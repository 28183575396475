const normalizePath = (path)=>{
    path = path.trim();
    path = path.split("?")[0];
    return path.endsWith("/") ? path : path + "/";
};
const stripTrailingSlash = (url)=>{
    if (!url) return url;
    if (url === "/") return "/";
    return url.endsWith("/") ? url.slice(0, -1) : url;
};
module.exports = {
    normalizePath,
    stripTrailingSlash
};
