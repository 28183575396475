function _object_without_properties(source, excluded) {
    if (source == null) return {};
    var target = _object_without_properties_loose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _object_without_properties_loose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
import { CLIENT_PERFORMANCE_MEASUREMENT } from "/constants/bigbrain-event-types";
import { trackEvent } from "/services/bigbrain-service";
const collect = (metric)=>{
    const { label, name, value } = metric, otherProps = _object_without_properties(metric, [
        "label",
        "name",
        "value"
    ]);
    trackEvent(CLIENT_PERFORMANCE_MEASUREMENT, {
        kind: name,
        info1: label,
        value,
        data: otherProps
    });
};
module.exports = {
    collect
};
