function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
const { BIGBRAIN_TRACKING_URL } = require("../../../constants/analytics");
const { logException } = require("../../logging/logger");
const { LOGGER_TAG_PARAM } = require("../../logging/consts");
//  can import with "import" only if you are in a "page", otherwise, should use "require"
const trackBigBrainEventOnServer = function() {
    var _ref = _async_to_generator(function*(name, bbArgs = {}) {
        try {
            const data = _object_spread({
                name,
                source: "monday_homepage"
            }, bbArgs);
            if (process.env.NODE_ENV !== "production") {
                console.log("Bigbrain event: ", data);
                return;
            }
            yield fetch(BIGBRAIN_TRACKING_URL, {
                method: "post",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json"
                }
            });
        } catch (e) {
            logException({
                externalMessage: "trackBigBrainEventOnServer exception",
                e,
                extraLogParams: {
                    [LOGGER_TAG_PARAM]: "bigbrain"
                }
            });
            console.error(e);
        }
    });
    return function trackBigBrainEventOnServer(name) {
        return _ref.apply(this, arguments);
    };
}();
module.exports = {
    trackBigBrainEventOnServer
};
