function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import { GOOGLE_TAG_MANAGER_HOMEPAGE_CONTAINER } from "/constants/marketing-keys";
import { isRunningInClient } from "services/dom-utils-service";
import { getUserAgentAnalyzedData } from "services/user-agent-service";
export const fireGtmEvent = (eventName, eventData = {})=>{
    if (!window.dataLayer) return;
    const data = _object_spread_props(_object_spread({}, eventData), {
        event: eventName
    });
    window.dataLayer.push(data);
};
export const updateDataLayerVariables = (variablesList)=>{
    if (!window.dataLayer) return;
    window.dataLayer.push(variablesList);
};
//fetches the lates variable from dataLayer: https://www.simoahava.com/analytics/fetch-latest-value-data-layer-variable/
export const getDataLayerVariable = (varName)=>{
    var _window, _window_google_tag_manager_GOOGLE_TAG_MANAGER_HOMEPAGE_CONTAINER_dataLayer, _window_google_tag_manager_GOOGLE_TAG_MANAGER_HOMEPAGE_CONTAINER;
    return ((_window = window) === null || _window === void 0 ? void 0 : _window.google_tag_manager) && ((_window_google_tag_manager_GOOGLE_TAG_MANAGER_HOMEPAGE_CONTAINER = window.google_tag_manager[GOOGLE_TAG_MANAGER_HOMEPAGE_CONTAINER]) === null || _window_google_tag_manager_GOOGLE_TAG_MANAGER_HOMEPAGE_CONTAINER === void 0 ? void 0 : (_window_google_tag_manager_GOOGLE_TAG_MANAGER_HOMEPAGE_CONTAINER_dataLayer = _window_google_tag_manager_GOOGLE_TAG_MANAGER_HOMEPAGE_CONTAINER.dataLayer) === null || _window_google_tag_manager_GOOGLE_TAG_MANAGER_HOMEPAGE_CONTAINER_dataLayer === void 0 ? void 0 : _window_google_tag_manager_GOOGLE_TAG_MANAGER_HOMEPAGE_CONTAINER_dataLayer.get(varName));
};
export const addGTMVariablesToWindow = ({ localeId, clusterId, hasFreeTier })=>{
    if (!isRunningInClient()) return;
    const userAgent = navigator.userAgent;
    const userAgentData = getUserAgentAnalyzedData(userAgent);
    window.gtmIsMobile = userAgentData.isMobile;
    window.gtmIsTablet = userAgentData.isTablet;
    window.gtmIsDesktop = userAgentData.isDesktop;
    window.gtmIsIos = userAgentData.isIos;
    window.gtmIsAndroid = userAgentData.isAndroid;
    window.gtmLocaleId = localeId;
    window.gtmClusterId = clusterId;
    window.gtmIsFreeTier = hasFreeTier;
};
