const DEBUG_LOG_LEVEL = "debug;";
const INFO_LOG_LEVEL = "info";
const WARNING_LOG_LEVEL = "warn";
const ERROR_LOG_LEVEL = "error";
// request props
const REQ_PROP_PATH = "path";
const REQ_PROP_URL = "url";
const REQ_PROP_REFERER = "referer";
const REQ_PROP_CLOUDFRONT_MOBILE_HEADER = "cloudfrontMobileHeader";
const REQ_PROP_CLOUDFRONT_TABLET_HEADER = "cloudfrontTabletHeader";
const REQ_PROP_CLOUDFRONT_DESKTOP_HEADER = "cloudfrontDesktopHeader";
const REQ_PROP_CLOUDFRONT_VIEWER_COUNTRY = "cloudfrontViewerCountry";
// response props
const RES_PROP_AB_TEST_EXACT_EVENT_NAME = "abTestExactEventName";
// Logger Param Names
const LOGGER_TAG_PARAM = "tag";
const LOGGER_PATH_PARAM = "path";
const LOGGER_VISITOR_ID_PARAM = "visitorId";
module.exports = {
    DEBUG_LOG_LEVEL,
    INFO_LOG_LEVEL,
    WARNING_LOG_LEVEL,
    ERROR_LOG_LEVEL,
    REQ_PROP_PATH,
    REQ_PROP_URL,
    REQ_PROP_REFERER,
    REQ_PROP_CLOUDFRONT_MOBILE_HEADER,
    REQ_PROP_CLOUDFRONT_TABLET_HEADER,
    REQ_PROP_CLOUDFRONT_DESKTOP_HEADER,
    REQ_PROP_CLOUDFRONT_VIEWER_COUNTRY,
    RES_PROP_AB_TEST_EXACT_EVENT_NAME,
    LOGGER_TAG_PARAM,
    LOGGER_PATH_PARAM,
    LOGGER_VISITOR_ID_PARAM
};
