import { isServerSide } from "utils/general";
import { trackBigBrainEventOnServer } from "/server/services/bigbrain-tracking-service/bigbrain-tracking-service";
import { MINI_SITE_CLUSTER_NOT_VALID } from "constants/bigbrain-event-types";
import { normalizePath } from "/server/services/url-service/url-service";
const getMiniSiteRootConfig = (clusterConfig, options = {})=>{
    if (options.useProductMiniSiteConfig && (clusterConfig === null || clusterConfig === void 0 ? void 0 : clusterConfig.product_mini_site)) return clusterConfig === null || clusterConfig === void 0 ? void 0 : clusterConfig.product_mini_site;
    return clusterConfig === null || clusterConfig === void 0 ? void 0 : clusterConfig.mini_site;
};
export const isMiniSiteConfigValid = (clusterConfig, options)=>{
    var _miniSiteConfig_logo, _miniSiteConfig_logo1, _miniSiteConfig_logo2, _miniSiteConfig_unifiedDesktopLogo, _miniSiteConfig_unifiedDesktopLogo1;
    const miniSiteConfig = getMiniSiteRootConfig(clusterConfig, options);
    const valid = miniSiteConfig && (miniSiteConfig === null || miniSiteConfig === void 0 ? void 0 : (_miniSiteConfig_logo = miniSiteConfig.logo) === null || _miniSiteConfig_logo === void 0 ? void 0 : _miniSiteConfig_logo.src) && (miniSiteConfig === null || miniSiteConfig === void 0 ? void 0 : (_miniSiteConfig_logo1 = miniSiteConfig.logo) === null || _miniSiteConfig_logo1 === void 0 ? void 0 : _miniSiteConfig_logo1.url) && (miniSiteConfig === null || miniSiteConfig === void 0 ? void 0 : (_miniSiteConfig_logo2 = miniSiteConfig.logo) === null || _miniSiteConfig_logo2 === void 0 ? void 0 : _miniSiteConfig_logo2.mobileSrc) && (miniSiteConfig === null || miniSiteConfig === void 0 ? void 0 : (_miniSiteConfig_unifiedDesktopLogo = miniSiteConfig.unifiedDesktopLogo) === null || _miniSiteConfig_unifiedDesktopLogo === void 0 ? void 0 : _miniSiteConfig_unifiedDesktopLogo.src) && (miniSiteConfig === null || miniSiteConfig === void 0 ? void 0 : (_miniSiteConfig_unifiedDesktopLogo1 = miniSiteConfig.unifiedDesktopLogo) === null || _miniSiteConfig_unifiedDesktopLogo1 === void 0 ? void 0 : _miniSiteConfig_unifiedDesktopLogo1.url) && validateMiniSiteLinks(miniSiteConfig === null || miniSiteConfig === void 0 ? void 0 : miniSiteConfig.links);
    if (!valid && isServerSide()) {
        trackBigBrainEventOnServer(MINI_SITE_CLUSTER_NOT_VALID, {
            kind: "mini_site",
            info1: clusterConfig === null || clusterConfig === void 0 ? void 0 : clusterConfig.name
        });
    }
    return valid;
};
const validateMiniSiteLinks = (links)=>{
    if (!links || (links === null || links === void 0 ? void 0 : links.length) < 0) return false;
    const validLinks = links.filter((link)=>link.url && link.text);
    return links.length === validLinks.length;
};
export const getMiniSiteLogo = (clusterConfig, options)=>{
    const miniSiteConfig = getMiniSiteRootConfig(clusterConfig, options);
    return miniSiteConfig === null || miniSiteConfig === void 0 ? void 0 : miniSiteConfig.logo;
};
export const getMiniSitePricingPageLink = (clusterConfig, options)=>{
    const miniSiteConfig = getMiniSiteRootConfig(clusterConfig, options);
    const links = miniSiteConfig === null || miniSiteConfig === void 0 ? void 0 : miniSiteConfig.links;
    if (!validateMiniSiteLinks(miniSiteConfig === null || miniSiteConfig === void 0 ? void 0 : miniSiteConfig.links)) return null;
    const pricingLink = links.filter((link)=>link.isPricingPage)[0]; //returns undefined if doesn't exist
    return pricingLink === null || pricingLink === void 0 ? void 0 : pricingLink.url; //can return null
};
export const shouldHideGeneralPricingInMinisite = (clusterConfig, options)=>{
    return !!getMiniSitePricingPageLink(clusterConfig, options);
};
export const getMiniSiteUnifiedDesktopLogo = (clusterConfig, options)=>{
    const miniSiteConfig = getMiniSiteRootConfig(clusterConfig, options);
    return miniSiteConfig === null || miniSiteConfig === void 0 ? void 0 : miniSiteConfig.unifiedDesktopLogo;
};
export const getMiniSiteLinks = (clusterConfig, options = {})=>{
    const miniSiteConfig = getMiniSiteRootConfig(clusterConfig, options);
    let links = miniSiteConfig === null || miniSiteConfig === void 0 ? void 0 : miniSiteConfig.links;
    if (options.onlyUnifiedLinks) {
        links = links.filter((link)=>!link.removeFromUnified);
    }
    return links;
};
export const getDynamicAssetConfigForPath = (clusterConfig, pagePath)=>{
    var _clusterConfig_dynamic_asset;
    if (!pagePath) return null;
    const normalizedPagePath = normalizePath(pagePath);
    const config = clusterConfig === null || clusterConfig === void 0 ? void 0 : (_clusterConfig_dynamic_asset = clusterConfig.dynamic_asset) === null || _clusterConfig_dynamic_asset === void 0 ? void 0 : _clusterConfig_dynamic_asset[normalizedPagePath];
    if ((config === null || config === void 0 ? void 0 : config.src) && (config === null || config === void 0 ? void 0 : config.alt)) {
        return config;
    }
    return null;
};
export const getDynamicTitleConfigForPath = (clusterConfig, pagePath)=>{
    var _clusterConfig_dynamic_title;
    if (!pagePath) return null;
    const normalizedPagePath = normalizePath(pagePath);
    return clusterConfig === null || clusterConfig === void 0 ? void 0 : (_clusterConfig_dynamic_title = clusterConfig.dynamic_title) === null || _clusterConfig_dynamic_title === void 0 ? void 0 : _clusterConfig_dynamic_title[normalizedPagePath];
};
export const getDynamicSubTitleConfigForPath = (clusterConfig, pagePath)=>{
    var _clusterConfig_dynamic_subtitle;
    if (!pagePath) return null;
    const normalizedPagePath = normalizePath(pagePath);
    return clusterConfig === null || clusterConfig === void 0 ? void 0 : (_clusterConfig_dynamic_subtitle = clusterConfig.dynamic_subtitle) === null || _clusterConfig_dynamic_subtitle === void 0 ? void 0 : _clusterConfig_dynamic_subtitle[normalizedPagePath];
};
export const getClusterDesktopLogo = (clusterConfig, options)=>{
    //currently each cluster with a minisite get the minisite logo
    return getMiniSiteUnifiedDesktopLogo(clusterConfig, options);
};
export const updateClusterFooterLinks = (clusterConfig, footerLinks, options)=>{
    const clusterPricingPageLink = getMiniSitePricingPageLink(clusterConfig, options);
    if (clusterPricingPageLink) {
        var _footerLinks_MondayFooterLinks;
        if (footerLinks === null || footerLinks === void 0 ? void 0 : (_footerLinks_MondayFooterLinks = footerLinks.MondayFooterLinks) === null || _footerLinks_MondayFooterLinks === void 0 ? void 0 : _footerLinks_MondayFooterLinks.links) {
            //Refactor together with entire footer links issue and unify with short footer + make generic
            footerLinks.MondayFooterLinks.links["footer.pricing"] = clusterPricingPageLink;
        }
    }
};
export const updateClusterShortFooterLinks = (clusterConfig, shortFooterLinks, options)=>{
    const clusterPricingPageLink = getMiniSitePricingPageLink(clusterConfig, options);
    if (clusterPricingPageLink) {
        const pricingItem = shortFooterLinks.find((item)=>item.title === "footer.pricing");
        pricingItem.url = clusterPricingPageLink;
    }
};
